.content {
    height: 100%;
}

#warning {
    height: 100%;
}

/* .fade {
    transition: display 1s linear !important;
} */

.master-legal-warning-row {
    text-align: left;
    overflow-x: auto;
    overflow-y: hidden;
    margin-left: 5vh;
    margin-right: 5vh;
}

.master-warning {
    color: #900;
    text-align: right;
}

.master-warning-body {
    font-size: 11px;
    height: 100%;
}

ul.template-link-list {
    text-align: center;
    padding: 0;
    width: 100%;
    border-bottom: none !important;
    display: block;
    margin: 5px 0 0 0;
}

.template-footer-row1 ul.template-link-list a {
    display: inline-block;
    padding: 0;
}

.template-footer-row1 ul.template-link-list li {
    padding: 0;
    margin: 0;
    display: inline-block;
    text-align: center;
}

.template-footer-row1 ul.template-link-list::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
}

.template-footer-row1 ul.template-link-list li::after {
    margin: 0 20px 0 20px;
    content: '|';
    padding: 0 0.15em;
}

.template-footer-row1 ul.template-link-list li:last-of-type::after {
    margin: 0;
    padding: 0;
    content: '';
}

.template-footer-align {
    text-align: center;
}

.style-guide-footer-hyperlink {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 14px !important;
    font-weight: normal !important;
    color: #bdd5ec;
}

.style-guide-body-text-smallest-white {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 10px !important;
    font-weight: normal !important;
    color: white !important;
}

.va-nav-header-row {
    color: white;
    margin-left: 15px;
}

/* menue breadcrumb */
.i5breadcrumb {
    margin-bottom: 0 !important;
}
