.navbar-hspa {
    background: #2054A7;
}

.navbar-hspa .navbar-nav .nav-link,
.navbar-hspa .dropdown .nav-link {
    text-decoration: none;
    font-size: 1rem;
    color: #fffa;
}

.navbar-hspa .nav-link:hover {
    color: #ffffffc0;
}
