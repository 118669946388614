body {
    margin: 0;
    padding: 0;
    font-family: Verdana, sans-serif;
    line-height: 1.4;
    overflow: hidden;
}

.ai-app-wrapper {
    height: 100%;
}

.appContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
    overflow-y: -webkit-paged-y;
}
.modal-backdrop {
    height: 100%;
    width: 100%;
}
.tabTitle {
    margin: 0 100px 0 0;
}

.full {
    height: 100%;
}

.tabHeader {
    border-bottom: grey 2px solid;
    margin: 0 20% 0 15px;
}

.footer,
.push {
    height: 100px;
}

.matchCapacityHeader {
    background-color: #a1d8a1;
}
.col-hidden {
    display: none;
}
.matchExpandRowColor {
    background-color: #faf9f9;
}

.threshold-type-label {
    margin-top: 15px;
    border: 2px solid #eee;
    border-radius: 3px;
    box-shadow: 2px 2px 2px grey;
}

a[href],
input[type='submit'],
input[type='image'],
label[for],
select,
button,
.pointer,
.bs-callout,
.nav-item {
    cursor: pointer;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#titleComponentField > div > div > span.Select-clear-zone {
    display: none !important;
}

.table td,
.table th {
    padding: 0.35rem;
}

.table-center-content td,
.table-center-content th {
    text-align: center;
}

.table-center-content th {
    border-top-width: 4px;
}

.table thead th {
    vertical-align: middle;
}
