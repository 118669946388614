@charset "UTF-8";

.nav-tabs--vertical > li > a {
  color: black !important;
  /* border-right: 1px solid #ddd; */
  display: flex;
  flex-flow: column nowrap;
}
/* .nav-tabs--vertical > li.active > a {
  border: 1px solid #ddd;
} */
.nav-tabs--vertical > li.active > a {
  border-color: #dee2e6 #dee2e6 #fff;
}







