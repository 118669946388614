.editable-cell {
    position: relative;
}

.editable-cell-value {
    cursor: pointer;
}

.editable-cell-input {
    width: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    margin: 0;
    height: 100%;
    border: none;
    padding: 10px;
    box-sizing: border-box;
}
