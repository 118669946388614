ol.progtrckr {
    list-style-type: none;
    padding: 0;
    text-align: center;
  }
  
  ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 4.5rem;
    cursor: pointer;
  }
  
  
  .progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
  }
  
  @media (max-width: 650px) {
    .progtrckr em {
      display: inline;
    }
  }
  
  ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 4px solid silver;
  }
  
  ol.progtrckr li.progtrckr-doing {
    color: black;
    border-bottom: 4px solid #5cb85c;
  }
  
  ol.progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 4px solid #5cb85c;
  }
  
  ol.progtrckr li:after {
    content: "\00a0\00a0";
  }
  
  ol.progtrckr li:before {
    position: relative;
    bottom: -3.7rem;
    float: left;
    left: 50%;
  }
  
  ol.progtrckr li.progtrckr-todo:before {
    content: "\039F";
    color: silver;
    background-color: white;
    width: 1.2em;
    line-height: 1.4em;
  }
  
  ol.progtrckr li.progtrckr-todo:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    color: white;
    background-color: #5cb85c;
    width: 1.4em;
    line-height: 1.4em;
    border-radius: 1.4em;
  }
  
  ol.progtrckr li.progtrckr-doing:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: #5cb85c;
    width: 1.4em;
    line-height: 1.4em;
    border-radius: 1.4em;
  }
  
  ol.progtrckr li.progtrckr-error:before {
    content: "\2716";
    color: white;
    background-color: #e90505;
    width: 1.4em;
    line-height: 1.4em;
    border-radius: 1.4em;
  }
  
  ol.progtrckr li.progtrckr-error {
    color: black;
    border-bottom: 4px solid #e90505;
  }
  
  ol.progtrckr li.progtrckr-done:hover:before {
    color: #333;
  }