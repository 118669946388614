@charset "UTF-8";

/* COLORS
    dark red:   #8C001A
    grey:       #ecf0f1
    dark blue:  #2C3E50
*/

.main-content {
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
    z-index: 2;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.btn-group-xs > .btn,
.btn-xs {
    padding: 0.25rem 0.4rem;
    font-size: 0.875rem;
    line-height: 0.5;
    border-radius: 0.2rem;
}

.myBtnGroup,
.navigation .btn {
    z-index: 0 !important;
}

.btn-color1,
.navigation .btn {
    color: #fff;
    background-color: #2156AB;
    border-color: #2156AB;
}
button.myBtnGroup:hover,
.navigation .btn:hover {
    color: white;
    background-color: #163A74;
    border-color: #163A74;
    z-index: 0 !important;
}

.btn-color1:focus,
.navigation .btn:focus {
    color: #fff;
    background-color: #163A74;
    border-color: #163A74;
}

button.myBtnGroup.active,
.navigation .btn.active:not(:disabled):not(.disabled) {
    background-color: #894343;
    border-color: #894343;
    box-shadow: 0 0 0 0.2rem #da7272;
    z-index: 0 !important;
}

button.myBtnGroup.active:focus,
.navigation .btn.active:not(:disabled):not(.disabled):focus {
    background-color: #713838;
    border-color: #713838;
    box-shadow: 0 0 0 0.2rem #da7272;
    z-index: 0 !important;
}

.titleBarDropdown {
    z-index: 1 !important;
}

.card-info {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

html {
    font-size: 10pt;
    word-break: keep-all;
}

.react-bs-table-search-clear-btn {
    line-height: 1.1;
}

table {
    font-family: Verdana, sans-serif !important;
}

.btn-group.d-flex {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    word-break: keep-all;
}

nav.nav-pills > a.nav-link {
    color: black !important;
}
nav.nav-pills > a.nav-link.active {
    color: white !important;
}
nav.nav-pills > a.nav-link:hover {
    color: black !important;
}
nav.nav-pills > a.nav-link.active:hover {
    color: white !important;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
