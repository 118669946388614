.metricGroupContainer{
    margin: 0 auto;
    padding-bottom: 20px;
    padding-top: 20px;
}
.cardContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.paginationContainer{
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}
