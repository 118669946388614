.bs-callout {
    padding: 10px 20px;
    border: 2px solid #eee;
    border-left-width: 7px;
    border-radius: 3px;
    box-shadow: 4px 4px 6px grey;
}

.bs-callout h4 {
    margin-bottom: 0;
}

.bs-callout-default {
    border-left-color: #777;
}

.bs-callout-default h4 {
    color: #777;
}

.bs-callout-primary {
    border-left-color: #0059ff;
}

.bs-callout-primary h4 {
    color: #0059ff;
}

.bs-callout-success {
    border-left-color: #5cb85c;
}

.bs-callout-success h4 {
    color: #5cb85c;
}

.bs-callout-danger {
    border-left-color: #d9534f;
}

.bs-callout-danger h4 {
    color: #d9534f;
}

.bs-callout-warning {
    border-left-color: #ffa500;
}

.bs-callout-warning h4 {
    color: #ffa500;
}

.bs-callout-info {
    border-left-color: #5bc0de;
}

.bs-callout-info h4 {
    color: #5bc0de;
}
