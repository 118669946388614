.stateCardContainer{
    background-color: inherit;
    border: none;
    padding: 0;
    width: 120px;
    height: 120px;
    text-decoration: underline;
    display: flex;
    flex-direction: column;
    box-shadow: none;
}

.stateGroupContainer{
    display: grid;
    align-items: center;
    grid-template-columns: repeat(10, 120px);
    grid-template-rows: repeat(5, 120px);
    row-gap: 30px;
}

.stateIcon{
    max-width: 70%;
    max-height: 70%;
    width: auto;
    height: auto;
    margin: auto;
}

.stateBanner{
    margin: 20px;
    margin-bottom: 80px;
    justify-content: center;
    text-decoration: none !important;
}